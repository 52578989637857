<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            內容管理中心
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'CollectionList' }">
            資料集列表
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯資料集' : '新增資料集'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li :class="{ active: step == '', done: collection.id }" @click="goToStep('')">
                <a>基本設定</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#fields', done: collection.config.fields && collection.config.fields.length > 0, disabled: !collection.id }" @click="goToStep('#fields')">
                <a>資料集欄位</a>
                <i class="fa fa-check-circle icon-for-done" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o icon-for-undone " aria-hidden="true"></i>
              </li>
              <li :class="{ active: step == '#advanced-setting', disabled: !collection.id }" @click="goToStep('#advanced-setting')">
                <a>進階設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本資料 section start -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">{{ isEditing ? '編輯資料集' : '新增資料集' }}</h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 資料集標題 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*資料集標題"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.collection.name.$error ? false: null"
                        v-model="collection.name"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.collection.name.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 表單標題 End -->
                    <!-- 資料集代碼 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*資料集代碼"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.collection.code.$error ? false: null"
                        v-model="collection.code"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.collection.code.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 資料集代碼 End -->
                    <!-- 資料集描述 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="資料集描述"
                    >
                      <b-form-textarea
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="collection.description"
                      ></b-form-textarea>
                    </b-form-group>
                    <!-- 表單描述 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 基本資料 section end -->

              <!-- 進階設定 section start -->
              <section v-if="step == '#fields'">
                <div>
                  <DynamicFormEditor
                    v-if="collection.id"
                    @output="saveFields"
                    @cancel="$router.push({ name: 'CollectionList' })"
                    editor-title="資料集欄位編輯"
                    :input-subjects="collection.config.fields"
                    :form-type="'collection'"
                  ></DynamicFormEditor>
                </div>
              </section>
              <!-- 進階設定 section end -->

              <!-- 進階設定 section start -->
              <section v-if="step == '#advanced-setting'">
                <h4 class="font-weight-bold">進階設定</h4>
                <DynamicFormReadOnly
                  v-if="collection.id"
                  :input-subjects="collection.config.fields"
                  @cancel="$router.push({ name: 'BookingPresetList' })"
                ></DynamicFormReadOnly>
              </section>
              <!-- 進階設定 section end -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import collectionApi from "@/apis/collection";
import DynamicFormEditor from '@/components/DynamicFormEditor/DynamicFormEditor.vue';
import DynamicFormReadOnly from '@/components/DynamicFormEditor/DynamicFormReadOnly.vue';

export default {
  components: {
    DynamicFormEditor,
    DynamicFormReadOnly,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      collection: {
        name: { required },
        code: { required },
      },
    };
  },
  data() {
    return {
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      step: '',
      collection: {
        name: null,
        code: null,
        description: null,
        config: {
          fields: [],
        }
      },
    }
  },
  mounted() {
    if (this.$route.name === "CollectionEdit") {
      this.isEditing = true;
      this.collection.id = this.$route.params.id;
      this.fetchCollection();
      this.navigateToStepFromCurrentRoute();
    } else {
      this.isEditing = false;
      this.collection = {
        name: null,
        code: null,
        description: null,
        config: {
          fields: [],
        }
      };
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'CollectionList' });
    },
    goToStep(step) {
      if (!this.collection.id) {
        return;
      }

      if (this.step == step) {
        return;
      }

      this.step = step
      if (this.$route.hash !== this.step) {
        this.$router.replace({ hash: this.step });
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return false;

      try {
        this.showLoading = true;
        // 編輯表單
        if (this.isEditing) {
          await collectionApi.updateCollection(this.collection.id, this.collection)
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          let response = await collectionApi.createCollection({ ...this.collection});
          this.collection = response.data.data;
          await this.$router.push({ name: "CollectionEdit", params: { id: this.collection.id } });
          return;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchCollection() {
      if (! this.collection.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await collectionApi.getCollection(this.collection.id);

        if (! response.data.data.config) {
          response.data.data.config = {
            fields: [],
          };
        }

        this.collection = response.data.data;

      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async saveFields(fields) {
      try {
        this.showLoading = true;

        this.collection.config.fields = fields;

        await collectionApi.updateCollection(this.collection.id, this.collection);
        this.$swal("儲存成功", "", "success");
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存資料錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
